import React, { useEffect, useState, useMemo } from "react";
import PricingCards from "./PricingCard";
import useFormData from "../util/useFormData";
//assets
import BizowalLogo from "../assets/logo-of-BIZOWL--Business-Services.png";
import SalesSupport from "../assets/Sales-Support 1.png";
import Call from "../assets/call.png";
import PhonePic from "../assets/PhoneIcon.png";
import Filter from "../assets/filter.svg";
import DownArrowPic from "../assets/Vector (5).png";
import Growth from "../assets/BizowlPic/growth 1.png";
import Price from "../assets/BizowlPic/best-price 1.png";
import Support from "../assets/BizowlPic/service 1.png";
import Quality from "../assets/BizowlPic/quality-control 1.png";
import Compare from "../assets/BizowlPic/compare 1.png";
import Experience from "../assets/BizowlPic/customer-review 2.png";
import { IoIosArrowDown, IoIosArrowUp } from "react-icons/io";
import { MdKeyboardArrowLeft } from "react-icons/md";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  Grid,
  Typography,
  FormControl,
  FormControlLabel,
  Radio,
  useMediaQuery,
  styled,
} from "@mui/material";
import "../serviceStyle.scss";
import CloseIcon from "@mui/icons-material/Close";
import { useNavigate } from "react-router-dom";
import { collection, getDocs } from "firebase/firestore";
import { partnerDB } from "../../../config/firebase";
import MobileFilters from "./MobileFilters";
import FilterCard from "./Filters";
import MobileViewCards from "./MobileViewCards";
import mockData from "./mockData.json";

const StyledFormControl = styled(FormControl)({
  display: "flex",
  flexDirection: "column", // Arrange items in a row
  alignItems: "start",
  color: "gray",
});

const StyledFormControlLabel = styled(FormControlLabel)({
  display: "flex",
  flexDirection: "row", // Arrange items in a row
  alignItems: "center",
  // Adjust spacing between radio and label
  fontSize: "5px",
});

const ServiceLogo = () => {
  const navigate = useNavigate();
  const [IncomingData, setIncomingData] = useState(mockData);
  const [data, setData] = useState(mockData);
  const [compareData, setcompareData] = useState([]);

  const { formData } = useFormData();
  const [filters, setFilters] = useState({
    priceRange: formData?.priceRange
      ? [formData.priceRange[0], formData.priceRange[1]]
      : [999, 10000],
    revisions: formData?.Revisions ? parseInt(formData.Revisions) : 1, // Ensure it's a number
    concepts: formData?.LogoConcepts ? parseInt(formData.LogoConcepts) : 1,
    TAT: formData?.deliveryTime ? parseInt(formData.deliveryTime) : "",
    PostDeliverySupport: "",
    AdditionalBrandingAssets: formData?.Assests || "",
    Deliverables: "",
  });

 
  useEffect(() => {
    handleReset();
  }, []);

  const [visibleItemId, setVisibleItemId] = useState(null);
  const [showMoreContent, setShowMoreContent] = useState(null);
  const [selectedServices, setSelectedServices] = useState([]);
  const [isChecked, setIsChecked] = useState({});
  const [checkboxState, setCheckboxState] = useState({});
  const [comparisonTrayVisible, setComparisonTrayVisible] = useState(false);
  const [actualPartners, setActualPartners] = useState([]);
  const [comparisonPartners, setComparisonPartners] = useState([]);
  const [isLoading, setIsLoading] = useState(true); // New loading state
  // State to manage the anchor element for the menu

  // State to manage the selected index

  const [selectedIndex, setSelectedIndex] = useState(null);

  //for filters icon in mobile size
  const [showOverlay, setShowOverlay] = useState(false);
  const toggleOverlay = () => {
    setShowOverlay(!showOverlay);
  };

  const [openMenu, setOpenMenu] = useState(null);
  const menuOptions = ["Default", "Price:High to Low", "Price:Low to High"];
  

  const handleSliderChange = (newRange) => {
    setFilters((prevData) => ({
      ...prevData,
      priceRange: newRange,
    }));
  };

  const handleRevisionSliderChange = (newRange) => {
    setFilters((prevData) => ({
      ...prevData,
      revisions: newRange,
    }));
  };

  const handleConceptSliderChange = (newRange) => {
    setFilters((prevData) => ({
      ...prevData,
      concepts: newRange,
    }));
  };

  const handleFilter = (event) => {
    const { name, value } = event.target;
    setFilters((prevFilters) => {
      return {
        ...prevFilters,
        [name]: value, // Handle other filters normally
      };
    });
  };

  const handleReset = () => {
    setFilters({
      priceRange: [999, 100000],
      revisions: 1,
      concepts: 1,
      TAT: "",
      PostDeliverySupport: "",
      AdditionalBrandingAssets: "",
      Deliverables: "",
    });
  };

  const applyFilters = (data, filters) => {
    return data
      .map((partner) => ({
        ...partner,
        Plans: partner.Plans.filter((plan) => {
          // Price Range Filter
          const isPriceInRange =
            plan.Price >= filters.priceRange[0] &&
            plan.Price <= filters.priceRange[1];
          if (!isPriceInRange) return false;

          // Revisions Filter
          let hasEnoughRevisions = true;
          if (filters.revisions > 0) {
            hasEnoughRevisions =
              plan["Revisions Included"] === "Unlimited" ||
              parseInt(plan["Revisions Included"]) >= filters.revisions;
          }
          if (!hasEnoughRevisions) return false;

          // Concepts Filter
          const hasEnoughConcepts =
            filters.concepts === 0 ||
            parseInt(plan.Concepts) >= filters.concepts;
          if (!hasEnoughConcepts) return false;

          // TAT (Turn Around Time) Filter
          const isTATInRange =
            filters.TAT === "" || plan["TAT (days)"] + " Days" === filters.TAT;
          if (!isTATInRange) return false;

          // Post Delivery Support Filter
          const hasCorrectPostDeliverySupport =
            !filters.PostDeliverySupport ||
            plan["Post Delivery Support"] === filters.PostDeliverySupport;
          if (!hasCorrectPostDeliverySupport) return false;

          // Additional Branding Assets Filter
          const areAllFilterAssetsInPlanAssets = (planAssets, filterAssets) => {
            const planAssetsSet = new Set(planAssets);
            return filterAssets.every((asset) => planAssetsSet.has(asset));
          };
          const planAssets = plan["Details of Assets"]
            .split(",")
            .map((asset) => asset.trim());

          const hasCorrectBrandingAssets =
            filters.AdditionalBrandingAssets.length === 0 ||
            areAllFilterAssetsInPlanAssets(
              planAssets,
              filters.AdditionalBrandingAssets
            );
          if (!hasCorrectBrandingAssets) return false;

          // Deliverables Filter
          const hasCorrectDeliverables =
            filters.Deliverables === "" ||
            plan.Deliverables.includes(filters.Deliverables);
          if (!hasCorrectDeliverables) return false;

          return true;
        }),
      }))
      .filter((partner) => partner.Plans.length > 0);
  };
  const filteredData = useMemo(
    () => applyFilters(IncomingData, filters),
    [IncomingData, filters]
  );

  useEffect(() => {
    setData(filteredData);
  }, [filteredData]);


  const displayItems = data;
  const displayCount = displayItems.length;
  const isMobile = useMediaQuery("(max-width:1000px)");

  // Open the menu
  const handleClick = (menuId) => {
    setOpenMenu((prevState) => (prevState === menuId ? null : menuId));
  };

  // sort by selection order
  const handleRadioChangeSort = (event) => {
    let selectedIndex = parseInt(event.target.value, 10);
    const sortedData = data.map((partner) => {
      return {
        ...partner,
        Plans: partner.Plans.sort((a, b) => {
          if (selectedIndex === 1) {
            // High to Low
            return b.Price - a.Price;
          } else if (selectedIndex === 2) {
            // Low to High
            return a.Price - b.Price;
          } else {
            // Default
            setData(IncomingData);
            return 0;
          }
        }),
      };
    });

    setData(sortedData);
  };

  const whyChooseBizowl = [
    {
      image: Growth,
      reason: "Business Growth Roadmap",
      alt: "Growth",
    },
    {
      image: Price,
      reason: "Best Price",
      alt: "BestPrice",
    },
    {
      image: Support,
      reason: "24*7 Support Assistance",
      alt: "Support",
    },
    {
      image: Quality,
      reason: "Best Quality, No disputes",
      alt: "Quality",
    },
    {
      image: Compare,
      reason: "Compare & Choose",
      alt: "Compare",
    },
    {
      image: Experience,
      reason: "Smoother Experience",
      alt: "Experience",
    },
  ];

  const adjustOpacity = (color, opacity) => {
    return color.replace(
      /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)/,
      (match, r, g, b, a) => `rgba(${r}, ${g}, ${b}, ${opacity})`
    );
  };

  const darkenColor = (color, percent) => {
    return color.replace(
      /rgba\((\d+),\s*(\d+),\s*(\d+),\s*(\d+)\)/,
      (match, r, g, b, a) => {
        const factor = (100 - percent) / 100;
        const rDark = Math.floor(r * factor);
        const gDark = Math.floor(g * factor);
        const bDark = Math.floor(b * factor);
        return `rgba(${rDark}, ${gDark}, ${bDark}, ${a})`;
      }
    );
  };

  const colors = [
    "rgba(112, 166, 230,1)",
    "rgba(160, 163, 216,1)",
    "rgba(207, 157, 187, 1)",
    "rgba(234, 211, 156, 1)",
    "rgba(16isM1, 212, 201, 1)",
    "rgba(14, 55, 104, 1)",
  ];

  // Data Fetching
  useEffect(() => {
    const fetchPartners = async () => {
      setIsLoading(true);
      try {
        const querySnapshot = await getDocs(collection(partnerDB, "newPrData"));
        const getPartners = querySnapshot.docs.map((doc) => {
          const data = doc.data();
          const baseColor = colors[Math.floor(Math.random() * colors.length)];
          const bgColor = adjustOpacity(baseColor, 0.3); // Low opacity for bgColor
          const borderColor = adjustOpacity(baseColor, 1); // Higher opacity for borderColor
          const letterColor = adjustOpacity(baseColor, 1); // Same as borderColor for letters

          return {
            ...data,
            id: data.id,
            bgColor: bgColor,
            borderColor: borderColor,
          };
        });

        setActualPartners(getPartners);
        console.log("Actual Partners=>",getPartners)
        setIsLoading(false);
      } catch (error) {
        console.error("Error fetching partners data:", error);
      }
    };

    fetchPartners();
  }, []);

  // ------------------------------------------------------------------------------------------------------------------

  const findPlanById = (data, serviceId) => {
    for (const partner of data) {
      const plan = partner.Plans.find((p) => p.id === serviceId);
      if (plan) {
        return { partnerName: partner["Partner Name"], plan };
      }
    }
    return null;
  };



  // Updated checkbox change handler
  const handleCheckboxChange = (event, serviceId) => {
    const isChecking = event.target.checked;
    // Find the partner and plan that matches the serviceId
    const partnerInfo = findPlanById(data, serviceId);

    setComparisonPartners(partnerInfo);

    // Check if maximum selections reached
    if (
      isChecking &&
      (isMobile ? compareData.length >= 2 : compareData.length >= 3)
    ) {
      event.preventDefault();
      return;
    }

    // Update checked state
    setCheckboxState((prevState) => ({
      ...prevState,
      [serviceId]: isChecking,
    }));

    const { plan } = partnerInfo;

    // Update selected services
    if (isChecking) {
      setcompareData((prevData = []) => [...prevData, partnerInfo]);
      setComparisonTrayVisible(true);
    } else {
      setcompareData((prevData) =>
        prevData.filter((partner) => partner.plan.id !== serviceId)
      );
      if (compareData.length <= 1) {
        setComparisonTrayVisible(false);
      }
      setSelectedServices((prevServices) =>
        prevServices.filter((service) => service.id != serviceId)
      );
    }
  };

  const handleIdFromCompareData = (serviceId) => {
    // Remove from selected services
    setCheckboxState((prevState) => {
      const newCheckboxState = { ...prevState };
      delete newCheckboxState[serviceId];
      return newCheckboxState;
    });
    setcompareData((prevData) => {
      const newCompareData = prevData.filter(
        (partner) => partner.plan.id !== serviceId
      );
      // Hide tray if no services left
      if (compareData.length <= 1) {
        setComparisonTrayVisible(false);
      }
      return newCompareData;
    });
  };

  // Updated comparison handler
  const handleCompare = () => {
    const queryString = Object.keys(checkboxState)
      .filter((serviceId) => checkboxState[serviceId])
      .join(",");

    // Include additional plan details in URL if needed
    const detailsString = selectedServices
      .map(
        (service) =>
          `&${service.id}-details=${encodeURIComponent(
            JSON.stringify({
              partner: service.partnerName,
              plan: service.planName,
              price: service.price,
            })
          )}`
      )
      .join("");

    navigate(
      `/services/logo-design-distribution/quote-details/compare-plans/?services=${queryString}${detailsString}`
    );
  };

  const handleCancel = () => {
    setComparisonTrayVisible(false);
    setcompareData([]);
    setCheckboxState({});
  };

  // click to open whatsapp in browser

  const handleClickWhatsApp = () => {
    const whatsappNumber = "919354651433";
    const whatsappURL = `https://wa.me/${whatsappNumber}`;
    window.open(whatsappURL, "_blank");
  };

  const handleMoreOption = (id) => {
    setVisibleItemId((prevId) => (prevId === id ? null : id));
    setShowMoreContent((prevId) => (prevId === id ? null : id));
  };

  if (isLoading) {
    return <p>Loading...</p>;
  }
  return (
    <div className="card">
      <div
        className="card-body"
        style={{ backgroundColor: "#c3ddf938", padding: isMobile ? "0" : "" }}
      >
        {isMobile ? (
          <div
            className="sticky top-0 left-0 w-screen bg-white  flex items-center justify-between p-3 "
            style={{
              boxShadow: "0 4px 15px 0 rgba(0, 0, 0, 0.25)", // Custom drop shadow
              zIndex: 300,
            }}
          >
            <div>
              {
                <Typography
                  variant="body2"
                  sx={{
                    display: "flex",
                    alignItems: "center",
                    fontSize: ".85rem",
                    fontWeight: "600", // Adjust font size if needed
                  }}
                >
                  <span style={{ fontSize: "2.5rem", marginRight: "0.5rem" }}>
                    <MdKeyboardArrowLeft />
                  </span>
                  Showing {displayCount} Plans
                </Typography>
              }
            </div>

            <div>
              <img src={BizowalLogo} alt="BizowlLogo" width="70px" />
            </div>
          </div>
        ) : (
          <div
            className="d-flex justify-content-between "
            style={{
              boxShadow: "0 4px 6.4px 0 #c6cac9",
              margin: "0px -15px 30px -15px",
              position: "fixed",
              top: "0",
              right: "0",
              left: "0",
              zIndex: "9999",
              backgroundColor: "#f7fcfb",
            }}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                justifyContent: "space-between",
                width: "90%",
                // marginTop:'8px',
                marginLeft: "4rem",
                backgroundColor: "#f7fcfb",
                paddingTop: "15px",
                paddingBottom: "15px",
              }}
            >
              <div
                className="d-flex align-items-center cursor-pointer"
                onClick={() => navigate("/")}
              >
                <img
                  src={BizowalLogo}
                  alt="BizowlLogo"
                  width={isMobile ? "120px" : "200px"}
                />
              </div>

              <div
                className="d-flex align-items-center gap-1 cursor-pointer"
                onClick={handleClickWhatsApp}
              >
                <img
                  src={PhonePic}
                  alt="PhonePic"
                  width={isMobile ? "30px" : "40px"}
                />
                <p>Need help?</p>
              </div>
            </div>
          </div>
        )}

        {!isMobile && (
          <div
            className="d-flex "
            style={{
              marginTop: "6rem",
            }}
          >
            <div className="m-5" style={{ width: "20rem" }}>
              <div>
                <div
                  className="card "
                  style={{
                    borderRadius: "25px",
                  }}
                >
                  <div
                    className="flex flex-col  align-items-center card-body"
                    style={{
                      boxShadow:
                        "0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)",
                      borderRadius: `25px`,
                      cursor: "pointer",
                    }}
                    onClick={() => handleClick("sortby")}
                  >
                    <div
                      className="flex justify-between w-[100%] "
                      style={{
                        marginBottom: "",
                      }}
                    >
                      <div>Sort By</div>
                      <div
                        style={{
                          border: "1px solid",
                          borderColor: "white",
                          borderRadius: "100%",
                          boxShadow: "0rem 0.25rem 0.25rem 0rem #407BFF",
                          display: "flex",
                          alignItems: "center",
                          justifyContent: "center",
                          width: "1.5rem",
                          height: "1.5rem",
                        }}
                      >
                        <img
                          src={DownArrowPic}
                          alt="DownArrowPic"
                          width="28rem"
                          height="30rem"
                          style={{ padding: "0.3rem", borderRadius: "100%" }}
                        />
                      </div>
                    </div>

                    {openMenu === "sortby" && (
                      <div>
                        <StyledFormControl component="fieldset">
                          {menuOptions.map((option, index) => (
                            <StyledFormControlLabel
                              key={option}
                              control={
                                <Radio
                                  checked={index === selectedIndex}
                                  onChange={handleRadioChangeSort}
                                  value={index}
                                />
                              }
                              label={option}
                            />
                          ))}
                        </StyledFormControl>
                      </div>
                    )}
                  </div>
                </div>
              </div>
              {/* filterss ---------------------------------------------------------------------  */}
              <FilterCard
                setFilters={setFilters}
                filters={filters}
                handleFilter={handleFilter}
                handleSliderChange={handleSliderChange}
                handleRevisionSliderChange={handleRevisionSliderChange}
                handleConceptSliderChange={handleConceptSliderChange}
                handleReset={handleReset}
              />
              {/* <whyChooseBizowl /> */}
              {/* why choose bizowl */}
              <div
                className="card"
                style={{
                  marginTop: "2rem",
                  backgroundColor: "#F6F6FD",
                  borderRadius: "25px",
                  boxShadow:
                    "0.3125rem 0.25rem 0.4375rem 0rem rgba(121, 168, 224, 0.65)",
                }}
              >
                <div
                  className="card-body"
                  style={{
                    borderRadius: "25px",
                  }}
                >
                  <h3
                    style={{
                      fontSize: "1.8rem",
                      textAlign: "left",
                      color: "#1B2559",
                    }}
                  >
                    Why Choose Bizowl?
                  </h3>
                  {whyChooseBizowl?.map((item, index) => (
                    <div
                      key={index}
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        justifyContent: "start",
                        alignItems: "end",
                        marginTop: "0.5rem",
                        marginBottom: "1rem",
                      }}
                    >
                      <img alt="" src={item?.image} width="30px" />
                      <h6
                        style={{
                          marginLeft: "1rem",
                          fontSize: "small",
                          textAlign: "left",
                        }}
                      >
                        {item?.reason}
                      </h6>
                    </div>
                  ))}
                </div>
              </div>
              {/* do not share thing */}
              <div
                className="card"
                style={{
                  marginTop: "2rem",
                  borderRadius: "25px",
                  boxShadow: `-5px -4px 7px 0px rgba(121, 168, 224, 0.55),5px 4px 7px 0px rgba(121, 168, 224, 0.55)`,
                }}
              >
                <div
                  className="card-body"
                  style={{
                    backgroundColor: "#F4F8FF",
                    alignItems: "start",
                    justifyContent: "center",
                    borderRadius: "25px",
                    padding: "0px 8px",
                  }}
                >
                  <div className="flex items-start justify-start gap-2 mt-5 mb-2 pl-2 pr-2">
                    <img src={SalesSupport} width="90px" />
                    <p className="text-sm text-left text-[#1B2559]">
                      Our executives can help you select the best plan for your
                      need.
                    </p>
                  </div>
                  <div
                    className="flex gap-2 justify-center items-center bg-white "
                    style={{
                      maxWidth: "120px",
                      margin: "0 auto 40px auto",
                      borderRadius: "10px",
                      cursor: "pointer",
                    }}
                    onClick={handleClickWhatsApp}
                  >
                    <img
                      src={Call}
                      width="20px"
                      style={{
                        marginTop: "10px",
                      }}
                    />
                    <p className="mt-2 text-[#1C6ED0] text-sm ">Talk to Us</p>
                  </div>
                </div>
              </div>
            </div>
            {/*actual page and cards ----------------------------------------------------- */}
            <PricingCards
              data={data}
              isExpanded={false} // Pass the actual isExpanded state here
              onToggleExpand={() => {}} // Pass the actual onToggleExpand function here
              isMobile={isMobile} // Pass the actual isMobile state here
              item={{}} // Pass the actual item object here
              card={{ id: 1 }} // Pass the actual card object here
              index={0} // Pass the actual index here
              checkboxState={checkboxState}
              handleCheckboxChange={handleCheckboxChange}
              selectedServices={selectedServices}
              handleMoreOption={handleMoreOption}
              visibleItemId={visibleItemId}
            />
            {/*actual page and cards ----------------------------------------------------- */}
          </div>
        )}

        {/* Mobile View ----------------------------------------------------- */}

        {isMobile && (
          <div
            className="flex justify-between "
            style={{
              marginTop: "20px",
              marginLeft: "3%",
            }}
          >
            {/* Sort BY */}
            <div>
              <Typography
                variant="body1"
                sx={{
                  border: "2px solid",
                  borderColor: `${
                    selectedIndex != null ? "#a3c2f7" : "#e5e7eb"
                  }`,
                  borderRadius: "100px",
                  padding: "0rem 0.5rem",
                  fontWeight: "400",
                  display: "flex",
                  alignItems: "center",
                  boxShadow: `${
                    selectedIndex != null
                      ? "0 1px 2px rgba(0, 102, 255, 0.2)"
                      : "0 1px 2px rgba(0, 0, 0, 0.05)"
                  }`,
                  cursor: "pointer",
                  transition: "all 0.3s ease",
                  position: "relative",
                }}
                onClick={() => handleClick("sortby")}
              >
                Sort By
                <span style={{ marginLeft: "0.5rem" }}>
                  {Boolean(openMenu) ? <IoIosArrowUp /> : <IoIosArrowDown />}
                </span>
                {openMenu === "sortby" && (
                  <div className="absolute flex  z-2">
                    <StyledFormControl
                      component="fieldset"
                      style={{
                        minWidth: "170px",
                        minHeight: "180px",
                        backgroundColor: "white",
                        borderRadius: "0px 20px 20px 20px",
                        marginTop: "13rem",
                        paddingLeft: "20px",
                        paddingTop: "20px",
                        marginLeft: "-15px",
                      }}
                    >
                      {menuOptions.map((option, index) => (
                        <StyledFormControlLabel
                          key={option}
                          control={
                            <Radio
                              checked={index === selectedIndex}
                              onChange={handleRadioChangeSort}
                              value={index}
                            />
                          }
                          label={option}
                          className="text-left"
                        />
                      ))}
                    </StyledFormControl>
                  </div>
                )}
              </Typography>
            </div>

            <div>
              <div
                style={{
                  border: "2px solid #e5e7eb",
                  borderRadius: "100px",
                  boxShadow: "0 1px 2px rgba(0, 0, 0, 0.05)",
                  padding: "0rem 1rem",
                  fontSize: "1.5rem",
                  marginRight: "-2px",
                  cursor: "pointer",
                }}
                onClick={toggleOverlay}
              >
                <img src={Filter} alt="Filter" />
              </div>
              <MobileFilters
                showOverlay={showOverlay}
                toggleOverlay={toggleOverlay}
                filters={filters}
                setFilters={setFilters}
                handleFilter={handleFilter}
                handleReset={handleReset}
                handleClick={handleClick}
                openMenu={openMenu}
                handleSliderChange={handleSliderChange}
                handleRevisionSliderChange={handleRevisionSliderChange}
                handleConceptSliderChange={handleConceptSliderChange}
              />
            </div>
          </div>
        )}

        {/* comparison tray  */}
        {comparisonTrayVisible && (
          <div
            className="comparison-tray"
            style={{
              display: "flex",
              flexDirection: `${isMobile && "row"}`,
              gap: `${isMobile && "70px"}`,
              zIndex: "1000",
            }}
          >
            <ul
              style={{
                display: "flex",
                flexWrap: "wrap",
                flexDirection: `${isMobile ? "column" : "row"}`,
                justifyContent: "space-between",
                alignItems: "center",
                width: isMobile ? "100%" : "70%",
              }}
            >
              {compareData?.map((partner) => {
                const { id } = partner;
                return (
                  <li
                    style={{
                      listStyle: "none",
                      padding: "5px",
                      flexBasis: "auto",
                      flexGrow: 1,
                      flexShrink: 0,
                      margin: "10px",
                      borderRadius: "10px",
                      boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
                      display: "flex",
                      flexDirection: "column",
                      alignItems: "center",
                      textAlign: "center",
                      position: "relative",
                      width: "12rem",
                      backgroundColor: "#F7FCFB",
                    }}
                    key={id}
                  >
                    <CloseIcon
                      className="close-icon"
                      onClick={() => handleIdFromCompareData(partner.plan.id)}
                      style={{
                        position: "absolute",
                        top: "5px",
                        right: "5px",
                        cursor: "pointer",
                        fontSize: "1rem",
                      }}
                    />
                    <div
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        alignItems: "center",
                        padding: "10px",
                      }}
                    >
                      <div
                        className="flex-1 rounded-[1rem]"
                        style={{
                          display: "flex",
                          margin: "10px",
                          border: "2px solid #A1D4C9",
                          padding: "10px",
                          alignItems: "center",
                          justifyContent: "center",
                          backgroundColor: "#F7FCFB",
                        }}
                      >
                        <span
                          style={{
                            fontWeight: "bold",
                            fontSize: "1.5rem",
                            color: "#455A64",
                          }}
                        >
                          {partner.partnerName.substring(0, 3).toUpperCase()}
                        </span>
                      </div>

                      <div style={{ flex: "1 1 0%", margin: "10px" }}>
                        <p
                          style={{
                            fontSize: "0.6rem",
                            color: "#455A64",
                            justifyContent: "center",
                          }}
                        >
                          Total Price Included GST
                        </p>
                        <p
                          style={{
                            color: "#fffffff",
                            fontWeight: "500",
                            fontSize: "1.2rem",
                          }}
                        >
                          ₹{partner.plan.Price}
                        </p>
                      </div>
                    </div>
                  </li>
                );
              })}

              {[...Array((isMobile ? 2 : 3) - (compareData?.length || 0))].map(
                (_, index) => (
                  <li
                    key={`placeholder-${index}`}
                    style={{
                      listStyle: "none",
                      padding: "5px",
                      flexBasis: "auto",
                      flexGrow: 1,
                      margin: "10px",
                      borderRadius: "10px",
                      // boxShadow: "0 0 10px rgba(0, 0, 0, 0.2)",
                      border: "1px solid blue", // Add a border
                      display: "flex",

                      justifyContent: "center",
                      alignItems: "center",
                      height: isMobile ? "6rem" : "7rem",
                    }}
                  >
                    <Typography variant="body2" color="#407bff">
                      Add a Plan
                    </Typography>
                  </li>
                )
              )}
            </ul>

            <div
              style={{
                display: "flex",
                flexDirection: `${isMobile ? "column" : "row"}`,
                justifyContent: "space-between",
                width: !isMobile && "30%",
                padding: !isMobile && "0 3rem 0 3rem",
              }}
            >
              <Button
                variant="contained"
                onClick={handleCompare}
                style={{ flex: 1, margin: "10px" }}
              >
                Compare
              </Button>
              <Button
                variant="outlined"
                onClick={handleCancel}
                style={{ flex: 1, margin: "10px" }}
              >
                Cancel
              </Button>
            </div>
          </div>
        )}
        {/*  mobile view code for actual cARd -------------------------------------------------*/}
        <MobileViewCards
          data={data}
          isMobile={isMobile}
          isChecked={isChecked}
          handleCheckboxChange={handleCheckboxChange}
          selectedServices={selectedServices}
          handleMoreOption={handleMoreOption}
          visibleItemId={visibleItemId}
          navigate={navigate}
        />
      </div>
    </div>
  );
};

export default ServiceLogo;
