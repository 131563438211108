  // Todo: Options change and error handling and that image in options in typeoflogo
  import React, { useEffect, useRef, useState } from "react";

  import logo from "./assets/logo.svg";
  import image from "./assets/Logo-Design.png";
  import talkExpert from "./assets/talkToExpert.svg";
  import percentage from "./assets/percentage.svg"; 
  import { Helmet } from "react-helmet";
  import "../../Styles/pr.css";
  import { useNavigate } from "react-router-dom";
  import { useMediaQuery } from "@mui/material";
  import ReactSlider from "react-slider";
  import { IoIosArrowDown } from "react-icons/io";
  import { addDoc, collection } from "firebase/firestore";
  import { partnerDB, primaryDB } from "../../config/firebase";
  import useFormData from "../Services/util/useFormData";
  import Apple from "./assets/OptionImages/Apple.png";
  import BurgerKing from "./assets/OptionImages/BurgerKing.png";
  import Harley from "./assets/OptionImages/Harley.png";
  import IBM from "./assets/OptionImages/IBM.png";
  import MAC from "./assets/OptionImages/MAC.png";
  import netflix from "./assets/OptionImages/netflix.png";
  import NIKE from "./assets/OptionImages/NIKE.png";
  import Pringles from "./assets/OptionImages/Pringles.png";
  import { DecisionParameters } from "../../data/WebDevelopmentForm/WebsiteFormData";
  import { RequiredFeatures } from "../../data/WebDevelopmentForm/WebsiteFormData";
  import { TechPreference } from "../../data/WebDevelopmentForm/WebsiteFormData";
  import { nanoid } from "nanoid";

const WebsiteDevelopmentForm = () => {

  const { updateFormData } = useFormData();

  const [currStep, setCurrStep] = useState(1);
  // for budget slider
  const [decisionParametersData,setDecisionParameterData]=useState(DecisionParameters);
  const [requiredFeaturesData,setRequiredFeaturesData]=useState(RequiredFeatures);
  const [techPreferenceData,setTechPreferenceData]=useState(TechPreference);
  const [errors, setErrors] = useState([]);
  const [enterDate,setEnterDate]=useState(false);
  const generateEnquiryId=()=>{
    return nanoid(16);
  }

  const getCreatedAtDate=()=>{
    const currentDate=new Date();
    return (currentDate.getDate()+"/"+(currentDate.getMonth()+1)+"/"+currentDate.getFullYear()+" "+currentDate.getHours()+":"+currentDate.getMinutes()+":"+currentDate.getSeconds());
  }

  const handleDataChange=(e)=>{
    const date=e.target.value;
    let dateString=date+"";
    const dateArray=dateString.split("-")
    dateArray.reverse()
    dateString=dateArray.join("-");
    setFormData((prevData)=>({
      ...prevData,serviceDetails:{...prevData.serviceDetails,projectStart:dateString}
    }))
  }

  const [formData, setFormData] = useState({
    status:"pending",
    enquiryID:generateEnquiryId(),
    createdAt:getCreatedAtDate(),
    categoryDetails:{
      category:"Website Development",
      subCategory:"Web Application",
      serviceName:"webDevelopment"
    },
    personalDetails:{
      fullName: "",
      business: "",
      email: "",
      phone: "",
      website: ""
    },
    projectDetails:{
      priceRange: [999, 10000],
      projectTimeline: "",
      contentReady: "",
      projectDetails: "",
    },
    serviceDetails:{
      purposeOfWebsite: "",
      numberOfPages: "",
      featuresRequired: [],
      techPreference: [],
      designPreference: "",
      domainHosting: "",
      decisionParameters: [],
      projectStart: "",
    }
  });

  const navigate = useNavigate();

  const handleSubmit = async (event) => {
    event.preventDefault();
    const newErrors = [];
    console.log("Form Data", formData);

    try {
        if (!formData.serviceDetails.purposeOfWebsite) {
            newErrors.purposeOfWebsite = "Purpose of Webiste is required.";
          }
      // Validate purposeOfPr
      if (!formData.personalDetails.fullName) {
        newErrors.fullName = "Full name is required.";
      }
      // Validate time
      if (!formData.personalDetails.email) {
        newErrors.email = "Email is required.";
      }
      // LogoStyle
      if (!formData.personalDetails.phone) {
        newErrors.phone = "Phone number is required.";
      } else if (formData.personalDetails.phone.length !== 10) {
        // Assuming a 10-digit phone number
        newErrors.phone = "Phone number must be 10 digits long.";
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        const collectionRef=collection(primaryDB,"clientFormData");
        await addDoc(collectionRef, formData);
        // console.log("Document written with ID: ", docRef.id);
        updateFormData(formData);
        // navigate("/services/logo-design-distribution/quote-details");
      }
    } catch (error) {
      // console.error("Error adding document: ", error);
    }
  };

//   const handleNext = (event) => {
//     event.preventDefault();
//     if (currStep < 3) {
//         setCurrStep(currStep + 1);
//       }
//     console.log("Form Data -> ", formData);

//     const newErrors = [];

//     // Validate purposeOfPr
//     if (!formData.purposeOfWebsite) {
//       newErrors.purposeOfWebsite = "Purpose of Webiste is required.";
//     }
//     // // Validate time
//     // if (!formData.deliveryTime) {
//     //   newErrors.deliveryTime = "Time is required.";
//     // }
//     // LogoStyle
//     // if (formData.LogoStyle.length === 0) {
//     //   newErrors.LogoStyle = "At least one LogoStyle is required.";
//     // }
//     // If there are errors, update state and do not go forward
//     if (Object.keys(newErrors).length > 0) {
//       setErrors(newErrors);
//     } else {
//       // Clear errors if no validation issues
//       setErrors([]);
//       setCurrStep(currStep+1);
//     }
//   };

  // console.log(errors);

  // Function to handle slider value change

 
  useEffect(()=>{
    updateDecisionParameters();
  },[decisionParametersData]);

  useEffect(()=>{
    updateRequiredFeatures();
  },[requiredFeaturesData]);

  useEffect(()=>{
    updateTechPreference();
  },[techPreferenceData]);
  
  const handleNext = (event) => {
    event.preventDefault();
    if (currStep < 3) {
      const newErrors = [];

      if (currStep === 1) {
        // if (!formData.purposeOfWebsite) newErrors.purposeOfWebsite = "Purpose of Website is required.";
        if (!formData.serviceDetails.numberOfPages) newErrors.numberOfPages = "Number of Pages is required.";
        // if (formData.featuresRequired.length === 0) newErrors.featuresRequired = "At least one feature is required.";
        // if (formData.techPreference.length === 0) newErrors.techPreference = "At least one Tech Preference is required.";
        // if (formData.designPreference.length === 0) newErrors.designPreference = "Design Preference is required.";
        if (formData.projectDetails.projectDetails.length === 0) newErrors.projectDetails = "Project Details are required.";
      } else if (currStep === 2) {
        // if (!formData.contentReady) newErrors.contentReady = "Content readiness selection is required.";
        // if (!formData.domainHosting) newErrors.domainHosting = "Domain/Hosting selection is required.";
         if (formData.serviceDetails.decisionParameters.length === 0) newErrors.decisionParameters = "Decision Parameters are required.";
         if (!formData.projectDetails.projectTimeline) newErrors.projectTimeline = "Project timeline is required.";
        // if (!formData.decisionParameters) newErrors.decisionParameters = "Decision Parameters are required.";
        // if (!formData.projectTimeline) newErrors.projectTimeline = "Project timeline is required.";
        // if (!formData.projectStart) newErrors.projectStart = "Project start selection is required.";

        // if (!formData.priceRange) newErrors.priceRange = "Budget Selection is required.";
        // if (!formData.priceRange || formData.priceRange[0] === 999 && formData.priceRange[1] === 10000) {
        //     newErrors.priceRange = "Budget selection is required.";
        //   }
      }

      if (Object.keys(newErrors).length > 0) {
        setErrors(newErrors);
      } else {
        setErrors([]);
        setCurrStep(currStep + 1);
      }
    }

    console.log("Form Data -> ", formData);
    // console.log("Form Data -> ", {
    //     ...formData,
    //     featuresRequired: formData.featuresRequired.includes("Other")
    //       ? [...formData.featuresRequired.filter((item) => item !== "Other"), formData.otherFeature]
    //       : formData.featuresRequired,
    //   });
  };
  const handleSliderChange = (newRange) => {
    setFormData((prevData) => ({
      ...prevData,
      projectDetails:{...prevData.projectDetails,priceRange:newRange} ,
    }));
  };

  const updateRequiredFeatures=()=>{
    let newArray=[]
    requiredFeaturesData.map((item)=>{
      return item.checked && newArray.push(item.value);
    });

    setFormData((prevData)=>({
      ...prevData,serviceDetails:{...prevData.serviceDetails,featuresRequired:newArray}
    }));
  }

  const updateTechPreference=()=>{
    let newArray=[]
    techPreferenceData.map((item)=>{
      return item.checked && newArray.push(item.value);
    });

    setFormData((prevData)=>({
      ...prevData,serviceDetails:{...prevData.serviceDetails,techPreference:newArray}
    }));
  }

  const updateDecisionParameters=()=>{
    let newArray=[]
    decisionParametersData.map((item)=>{
      return item.checked && newArray.push(item.value);
    });

    setFormData((prevData)=>({
      ...prevData,serviceDetails:{...prevData.serviceDetails,decisionParameters:newArray}
    }));
  }

  const handleDecisionCheckboxChange=(e)=>{
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const {value,checked}=e.target;
    setDecisionParameterData((prevData)=>
      prevData.map((item)=>{
        return item.value === value ? {...item,checked:checked}:item
      })
    )
  }

  const handleFeaturesCheckboxChange=(e)=>{
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const {value,checked}=e.target;
    setRequiredFeaturesData((prevData)=>
      prevData.map((item)=>{
        return item.value === value ? {...item,checked:checked}:item
      })
    )
  }

  const handleTechPreferenceCheckboxChange=(e)=>{
    if (!e || !e.target) {
      console.error("Event or event.target is undefined");
      return;
    }
    const {value,checked}=e.target;
    setTechPreferenceData((prevData)=>
      prevData.map((item)=>{
        return item.value === value ? {...item,checked:checked}:item
      })
    )
  }
 

  const handleCheckboxChange = (group, event) => {
    if (!event || !event.target) {
        console.error("Event or event.target is undefined");
        return;
      }
    const { value, checked } = event.target;
    setFormData((prevData) => {
        console.log("Previous State:", prevData);
      const newSelections = checked
        ? ({...prevData.serviceDetails,serviceDetails:[...prevData.serviceDetails[group],value]})
        :({...prevData.serviceDetails,serviceDetails:[...prevData.serviceDetails[group].filter((item) => item !== value)]});
        console.log("New selections:",newSelections)

      // return { ...prevData, [group]: newSelections };
    });
  };

  const handlePersonalDetails=(e)=>{
    const {name,value}=e.target;
    setFormData((prevData)=>({
      ...prevData,personalDetails:{...prevData.personalDetails,[name]:value}
    }))
  }

  const handleProjectDetails=(e)=>{
    const {name,value}=e.target;
    setFormData((prevData)=>({
      ...prevData,projectDetails:{...prevData.projectDetails,[name]:value}
    }))
  }

  const handleServiceDetails=(e)=>{
    const {name,value}=e.target;
    setFormData((prevData)=>({
      ...prevData,serviceDetails:{...prevData.serviceDetails,[name]:value}
    }))
  }


  const isMobile = useMediaQuery("(max-width: 768px)");
  const isTab = useMediaQuery("(min-width: 769px) and (max-width:1476px) ");
  const labelStyle = {
    width: !isMobile ? "400px" : "", // Set width to 400px if not mobile
    textAlign: "left",
    // gap:isMobile?"5px":""
  };

  const dropdownRef = useRef({});
  const buttonRef = useRef({});
  const validateStep = () => {
    const newErrors = {};

    if (currStep === 1) {
      if (!formData.serviceDetails.purposeOfWebsite) newErrors.purposeOfWebsite = "Purpose of Website is required";
      if (!formData.serviceDetails.numberOfPages) newErrors.numberOfPages = "Number of Pages is required";
      if (formData.featuresRequired.length === 0) newErrors.featuresRequired = "At least one feature is required";
    } else if (currStep === 2) {
      if (!formData.projectDetails.contentReady) newErrors.contentReady = "Content readiness selection is required";
      if (!formData.serviceDetails.domainHosting) newErrors.domainHosting = "Domain/Hosting selection is required";
      
      if (!formData.projectDetails.projectTimeline) newErrors.projectTimeline = "Project timeline is required";
      if (!formData.serviceDetails.projectStart) newErrors.projectStart = "Project start selection is required";
    }

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0;
  };

  // Close the dropdown if a click is detected outside of it
  useEffect(() => {
    // const handleClickOutside = (event) => {
    //   Object.keys(dropdownRef.current).forEach((key) => {
    //     if (
    //       dropdownRef.current[key] &&
    //       !dropdownRef.current[key].contains(event.target) &&
    //       !buttonRef.current[key].contains(event.target)
    //     ) {
    //       dropdownRef.current[key].classList.add("hidden");
    //     }
    //   });
    // };

    // document.addEventListener("click", handleClickOutside);

    // return () => {
    //   document.removeEventListener("click", handleClickOutside);
    // };
    const handleClickOutside = (event) => {
        Object.keys(dropdownRef.current).forEach((key) => {
          const dropdownElement = dropdownRef.current[key];
          const buttonElement = buttonRef.current[key];
          if (
            dropdownElement &&
            buttonElement &&
            !dropdownElement.contains(event.target) &&
            !buttonElement.contains(event.target)
          ) {
            dropdownElement.classList.add("hidden");
          }
        });
      };
  
    document.addEventListener("mousedown", handleClickOutside);
  
    return () => {
      document.removeEventListener("mousedown", handleClickOutside);
    };
  }, []);

  // Toggle the dropdown when the button is clicked
//   const toggleDropdown = (id) => {
//     // dropdownRef.current[id].classList.toggle("hidden");
//     if (dropdownRef.current[id]) {
//         dropdownRef.current[id].classList.toggle("hidden");
//       }
    
//   };
const toggleDropdown = (id) => {
    const dropdownElement = dropdownRef.current[id];
    if (dropdownElement) {
      dropdownElement.classList.toggle("hidden");
    }
  };
  


//   return <ServiceForm serviceConfig={serviceConfig} onSubmit={handleSubmit} />;
return(
    <div className="main">
        <div
          className={isMobile ? "" : "left-side"}
          style={{
            // display: isMobile ? "flex" : "",
            className: isMobile ? "" : "left-side",
            flexDirection: isMobile ? "column" : "",
            justifyContent: isMobile ? "start" : "",
            alignItems: isMobile ? "flex-start" : "", // Align items to the start (left) of the container
            padding: isMobile ? "0 30px" : "", // Optional: Add padding to avoid elements touching the container edges
            gap: isMobile ? "15px" : "",
          }}
        >
          <br />
          <br />
          {/* Talk to an expert */}
          <div
            className="logo"
            style={{
              display: isMobile ? "flex" : "",
              justifyContent: isMobile ? "space-between" : "flex-start", // Align items based on screen size
              // gap: isMobile ?"40px":"",
              width: isMobile ? "100%" : "", // Ensure the logo div takes up the full width for proper alignment
              marginTop: isMobile ? "-20px" : "",
            }}
          >
            <img
              src={logo}
              alt="logo"
              onClick={() => navigate("/")}
              style={{
                width: !isMobile && "35%",
              }}
            />
            <p style={{ textAlign: "left" }}>
              <img src={talkExpert} alt="" />
              <a
                href="tel:+919354651433"
                style={{ textDecoration: "none", color: "inherit" }}
              >
                Talk to an Expert ( +919354651433)
              </a>
            </p>
          </div>

          {/* inner comopnent */}
          <div style={{ display: isMobile ? "flex" : "" }}>
            <div
              className=" "
              style={{
                flexDirection: "column",
                justifyContent: "center",
                alignItems: "start",
                gap: isMobile ? "8px" : "10px",
                margin: isMobile ? "20px auto" : "20px auto",
                marginRight: isMobile ? "0" : "10%",
                // fontSize: isMobile ? "14px" : "10px auto auto",
              }}
            >
              <p
                className="text-left text-base"
                style={{ fontSize: isMobile ? "10px" : "10px auto auto" }}
              >
                Website Development 
              </p>

              <p
                className="text-[30px] space-y-1"
                style={{
                  textAlign: "left",
                  lineHeight: isMobile ? "1.5" : "1.2",
                  fontSize: isMobile ? "15px" : "",
                }}
              >
                Craft Your Dream Website With Expert{" "}
                <span className="text-[#1C6ED0] font-semibold ">
                  Care And Great Deals!
                </span>
              </p>

              {/* <ul
                className="flex-wrap list-disc mt-2 sm:gap-6 text-[#1C6ED0] text-[12px] justify-start"
                style={{
                  textAlign: "left",
                  paddingInlineStart: "20px",
                  fontSize: isMobile ? "12px" : "15px",
                  display: isMobile ? "flex-col" : "flex",
                }}
              >
                <li>Business Branding</li>
                <li>Company Rebranding</li>
                <li>Custom Logo Design</li>
              </ul> */}
            </div>

            {/* Main image */}
            <div className=" flex align-baseline">
              <img
                src={image}
                alt=""
                width={isMobile ? "300px" : "80%"}
                style={{
                  display: "block",
                  margin: isMobile ? "0 auto" : "10px auto auto",
                }}
                className=""
              />
            </div>
          </div>
        </div>
        {/* end here */}

        <div className="md:w-[50%] md:absolute left-[50%]">
          <br />
          <br />
          <p
            className={`text-[#1B2559] hidden  text-[12px] font-bold md:flex items-center justify-end pr-2 ${
              isMobile ? "" : isTab ? "auto" : "w-[90%]"
            }`}
          >
            <img src={talkExpert} alt="" />
            <p>Talk to an Expert ( +919354651433)</p>
          </p>

          {/* <div className="flex items-end justify-center text-[15px] mt-3">
            <br />
            <div className="flex flex-col items-center justify-center">
              <p>Website Details</p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle cx="12.5" cy="12.5" r="12.5" fill="#1C6ED0" />
              </svg>
              <dot />
            </div>
            <div className="mt-4">
              <svg
                width="200"
                height="1"
                viewBox="0 0 300 1"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <line
                  y1="0.5"
                  x2="300"
                  y2="0.5"
                  stroke="#455A64"
                  stroke-opacity="0.75"
                />
              </svg>
            </div>

            <div className="flex flex-col items-center justify-center">
              <p>Contact Details</p>
              <svg
                width="20"
                height="20"
                viewBox="0 0 25 25"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
              >
                <circle
                  cx="12.5"
                  cy="12.5"
                  r="12.5"
                  fill={currStep === 1 ? "#455A64" : "#1C6ED0"}
                />
              </svg>
            </div>
          </div> */}

          <br />
          <div
            className={`flex flex-col justify-center items-center w-[95%] md:w-[85%] lg:w-[65%] m-auto rounded-b-3xl form  ${
              isMobile ? "max-w-[375px]" : ""
            }`}
          >
            <p className="flex items-center bg-gradient-to-r from-[#1C6ED0] to-[#0E386A] text-white w-full justify-between rounded-t-3xl p-1 gap-2 relative">
            <div className="flex items-center gap-2">
              <img src={percentage} alt="" className={`${isMobile? "pl-2":"pl-2"}`} />
              <span className="text-xs">
                Compare and Get the Bestest Deals
              </span>
              </div>
              <div
      className="flex items-center justify-center text-xs font-medium"
      style={{
        position: "absolute",
        right: "10px",
        top: "50%",
        transform: "translateY(-50%)",
        width: "80px",
        height: "25px",
        background: "#D7E9FD",
        color:"#1C6ED0",
        borderRadius: "18px",
      }}
    >
      Step {currStep}/3
    </div>
              {/* Compare and Get the Best Logo design Deals */}
            </p>
            <div className="flex flex-col justify-start">
              {/* <p
                className="font-bold text-[18px] lg:text-[24px] "
                style={{
                  marginTop: isMobile ? "19px" : isTab ? "23px" : "10px",
                  marginBottom: isMobile ? "9px" : "11px",
                  marginLeft: isMobile ? "-48px" : isTab ? "1%" : "-90px",
                  fontWeight: !isMobile && "600",
                }}
              > */}
              <div
                style={{
                  marginTop: isMobile ? "19px" : "23px",
                  marginBottom: isMobile ? "9px" : "11px",
                  marginLeft: isMobile ? "-48px" : isTab ? "" : "-40px",
                }}
              >
                {currStep === 1 && (
                  <p
                    className={`${
                      isMobile
                        ? "text-xl container pl-20 text-left"
                        : isTab
                        ? "w-[320px] text-left"
                        : "w-[320px] text-left"
                    } `}
                  >
                    {/* Get Your Custom Logo{" "} */}
                    <div className="text-base  font-medium">
                      <span className="">Build your dream website with </span>
                      <span className="text-[#1C6ED0]">top-notch expertise</span>
                    </div>
                  </p>
                ) }
                 {currStep === 2 &&   (
                  <p
                    className={`${
                      isMobile
                        ? ""
                        : isTab
                        ? "w-[320px] text-left"
                        : "w-[320px] text-left"
                    }`}
                  >
                    Additional Information
                  </p>
                )}
              {currStep === 3 &&   (
                  <p
                    className={`${
                      isMobile
                        ? ""
                        : isTab
                        ? "w-[320px] text-left"
                        : "w-[320px] text-left"
                    }`}
                  >
                    Enter Your Contact Details
                  </p>
                )}
              </div>
              {/* </p> */}

              <form
                action=""
                className="mainForm"
                style={{
                  marginLeft: isMobile ? "16px" : isTab ? "1%" : "-80px",
                  gap: isMobile ? "12px" : "25px",
                  width: isMobile ? "" : isTab ? "320px" : "375px",
                }}
              >
                {
                currStep === 1  && (
                <>
                    {/* Form starts here ------------------------------------------------------------------------------- */}
                    {/* Type of logo */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >

                      <label htmlFor="TypeOfLogo" className="formLabels" style={labelStyle}>
                        Purpose of Website? (E-commerce, Blog, Static, Portfolio)
                        <br />
                       
                        <select
                  id="purposeOfWebsite"
                  name="purposeOfWebsite"
                  value={formData.serviceDetails.purposeOfWebsite}
                  onChange={handleServiceDetails}
                  className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="E-commerce">E-commerce</option>
                  <option value="Blog">Blog</option>
                  <option value="Portfolio">Portfolio</option>
                  <option value="Informational/Business Website">Informational/Business Website</option>
                  <option value="Booking/Appointment Platform">Booking/Appointment Platform</option>
                  <option value="Membership/Subscription Platform">Membership/Subscription Platform</option>
                  <option value="Event or Campaign Website">Event or Campaign Website</option>
                  <option value="Landing Page">Landing Page</option>
                  <option value="News/Media Website">News/Media Website</option>
                  <option value="Other">Other</option>
                </select>
                {/* {errors.purposeOfWebsite && <span style={{ color: "red" }}>{errors.purposeOfWebsite}</span>} */}
                {formData.serviceDetails.purposeOfWebsite === "Other" && (
                  <input
                    type="text"
                    name="purposeOfWebsite"
                    onChange={handleServiceDetails}
                    placeholder="Please specify"
                    className="mt-2 border rounded p-1 w-[75%]"
                  />
                )}
                 </label> 
                      {/* <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="LogoConcepts"
                        placeholder="Blog, Static Website, Portfolio"
                        name="purposeOfWebsite"
                        value="Blog, Static Website, Portfolio"
                        // value={formData.LogoConcepts}
                        defaultValue="Blog, Static Website, Portfolio"
                        onChange={handleFormData}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                       
                        <option disabled value="">
                        {/* Blog, Static Website, Portfolio */}
                        {/* </option>
                        <option value="E-commerce">E-commerce</option>
                        <option value="Blog">Blog</option>
                        <option value="Portfolio">Portfolio</option>
                        <option value="Informational/Business Website">Informational/Business Website</option>
                        <option value="Booking/Appointment Platform">  Booking/Appointment Platform</option>
                        <option value="Membership/Subscription Platform"> Membership/Subscription Platform</option>
                        <option value="Event or Campaign Website">Event or Campaign Website</option>
                        <option value="Landing Page"> Landing Page</option>
                        <option value="News/Media Website"> News/Media Website</option>
                       
                      </select>*/} 
                     
                     

                      {/* <div
                        ref={(el) => (buttonRef.current["dropdown1"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown1")}
                      >
                        {/* {formData.TypeOfLogo.length > 0 ? (
                          formData.TypeOfLogo.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Blog, Static Website, Portfolio</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )} */}

                        {/* <div
                          id="dropdown1"
                          ref={(el) => (dropdownRef.current["dropdown1"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                        >
                          {TypeOfLogo_Value.map((option, index) => (
                            <label
                              key={index}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                justifyContent: "space-between",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                              }}
                            >
                              <div className="flex gap-2">
                                <input
                                  type="checkbox"
                                  value={option.label}
                                  checked={formData.TypeOfLogo.includes(
                                    option.label
                                  )}
                                  onChange={(e) =>
                                    handleCheckboxChange("TypeOfLogo", e)
                                  }
                                  className="ml-2"
                                />
                                {option.label}
                              </div>
                              <img
                                src={option.value}
                                alt={option.label}
                                className="w-[100px] h-[50px] object-contain mr-5 border border-gray-300 p-2 rounded shadow"
                              />
                            </label>
                          ))}
                        </div> */}
                      {/* </div> */} 
                    </div>

                    {/* Logo Style */}

                    {/* <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      {/* <label htmlFor="LogoStyle" classname="formLabels ">
                        Number of pages on website?
                      </label> */}
                      {/* {errors.LogoStyle && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.LogoStyle}
                          <sup>*</sup>
                        </div>
                      )} */}
                      {/* <div
                        ref={(el) => (buttonRef.current["dropdown3"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown3")}
                      >
                        {formData.LogoStyle.length > 0 ? (
                          formData.LogoStyle.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>
                              {" "}
                              5
                            </p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        {/* <div
                          id="dropdown3"
                          ref={(el) => (dropdownRef.current["dropdown3"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                          aria-required
                        >
                          {LogoStyle.map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                                gap: "10px",
                              }}
                            >
                              {/* <input
                                type="checkbox"
                                value={option}
                                checked={formData.LogoStyle.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("LogoStyle", e)
                                }
                              /> */}
                              {/* {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div> */}
                      {/* </div>  
                    </div> */}
                    <label style={labelStyle} htmlFor="numberOfPages">
                      Number of pages on website?
                      <br />
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="numberOfPages"
                        name="numberOfPages"
                        defaultValue=""
                        value={formData.serviceDetails.numberOfPages}
                        onChange={handleServiceDetails}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="1-5 Pages">1-5 Pages</option>
                        <option value="6-10 Pages">6-10 Pages</option>
                        <option value="11-20 Pages">11-20 Pages</option>
                        <option value="21+ Pages">21+ Pages</option>
                        <option value="Unsure">Unsure</option>
                      </select>
                      {errors.numberOfPages && <span style={{ color: "red" }}>{errors.numberOfPages}</span>}
                    </label>
                    {/* Inital Logo concepts */}
                    {/* <label style={labelStyle} htmlFor="LogoConcepts">
                      Features Required
                      <br />
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="featuresRequired"
                        name="featuresRequired"
                        defaultValue=""
                        value={formData.featuresRequired}
                        onChange={handleFormData}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                        <option disabled value="">
                          Select
                        </option>
                        <option value="1">Live Chat</option>
                        <option value="2">Forms</option>
                        <option value="3">SEO Optimization</option>
                        <option value="3+">3+</option>
                      </select>
                    </label> */}
                    {/* new */}
                    {/* <label style={labelStyle} htmlFor="featuresRequired">
                Features Required!
                <br />
                <div
                id="dropdown3"
                  style={{
                    width: isMobile ? "" : isTab ? "300px" : "400px",
                  }}
                  className="relative"
                >
                  <div
                  id="dropdown3"
                    ref={(el) => (dropdownRef.current["dropdown3"] = el)}
                    // dropdownRef.current[id].classList.toggle("hidden");
                    onClick={() => toggleDropdown("dropdown3")}
                    className="bg-[#18141f05] p-2 cursor-pointer rounded flex justify-between items-center "
                  >
                    <span className="text-[grey]">Select Features</span>
                    <IoIosArrowDown />
                  </div>
                  <div
                    id="dropdown3"
                    className="hidden absolute bg-white border border-gray-300 rounded mt-1 w-full z-10 max-h-[150px] overflow-y-auto"
                    onClick={() => toggleDropdown("dropdown3")}
                  >
                    {["Live Chat", "Contact/Feedback Forms", "SEO Optimization", "Payment Gateway Integration", "Social Media Integration", "Newsletter Subscription", "User Accounts/Registration", "Multilingual Support", "Search Functionality", "Analytics and Reporting Tools", "Custom Animations", "Video or Audio Integration", "Booking/Calendar System", "Product Catalog", "Other"].map((option, idx) => (
                      <label
                        key={idx}
                        className="flex items-center p-2 hover:bg-gray-100 cursor-pointer"
                      >
                        <input
                          type="checkbox"
                          value={option}
                          checked={formData.featuresRequired.includes(option)}
                          onChange={(e) => handleCheckboxChange("dropdown3", e)}
                          className="mr-2"
                        />
                        {option}
                      </label>
                    ))}
                  </div>
                </div>
              </label> */}
              <div  
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
      <label style={labelStyle} htmlFor="featuresRequired"  className="formLabels">
        Features Required
      </label>
      <div
        id="dropdown3"
        style={{
            width: isMobile ? "" : isTab ? "300px" : "400px",
          }}
        className="relative"
      >
        <div
          ref={(el) => (buttonRef.current["dropdown3"] = el)}
          onClick={() => toggleDropdown("dropdown3")}
          className="bg-[#18141f05] p-2 cursor-pointer rounded flex justify-between items-center"
        >
          <span className="text-[grey]">
            {formData.serviceDetails.featuresRequired.length > 0
              ? formData.serviceDetails.featuresRequired.join(", ")
              : "Select Features"}
          </span>
          <IoIosArrowDown />
        </div>
        <div
          ref={(el) => (dropdownRef.current["dropdown3"] = el)}
          className="hidden absolute bg-white border border-gray-300 rounded mt-1 w-full z-10 max-h-[150px] overflow-y-auto text-[grey]"
        >
          {requiredFeaturesData.map((option, idx) => (
            <label
              key={idx}
              className="flex items-center  p-2 hover:bg-gray-100 cursor-pointer"
              style={{
                display: "flex",
                flexDirection: "row",
                // alignItems: "start",
            
                padding: "5px",
                // background: "#18141f05",
                gap: "10px",
              }}
            >
               
              <input
                type="checkbox"
                value={option.value}
                checked={option.checked}
                onChange={handleFeaturesCheckboxChange}
                className="mr-2 "
                
                onClick={(e) => e.stopPropagation()}
              />
              {option.value}
              {option.value === "Other" && (
                        <input
                          type="text"
                          name="otherFeature"
                          onChange={handleServiceDetails}
                          placeholder="Please specify"
                          className="ml-2 border rounded p-1"
                        />
                      )}



                      
            </label>
            
          ))}
            
        </div>
        {/* {errors.featuresRequired && <span style={{ color: "red" ,display: "block", textAlign: "start" }}>{errors.featuresRequired}</span>} */}
      </div>
    </div>


    {/* updated tech pref */}
    <div  style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}>
      <label style={labelStyle} htmlFor="techPreference"  className="formLabels">
        Tech Preference?
      </label>
      <div
        id="dropdown4"
        style={{
            width: isMobile ? "" : isTab ? "300px" : "400px",
          }}
        className="relative"
      >
        <div
          ref={(el) => (buttonRef.current["dropdown4"] = el)}
          onClick={() => toggleDropdown("dropdown4")}
          className="bg-[#18141f05] p-2 cursor-pointer rounded flex justify-between items-center"
        >
          <span className="text-[grey]">
            {formData.serviceDetails.techPreference.length > 0
              ? formData.serviceDetails.techPreference.join(", ")
              : "Select Tech Preference"}
          </span>
          <IoIosArrowDown />
        </div>
        <div
          ref={(el) => (dropdownRef.current["dropdown4"] = el)}
          className="hidden absolute bg-white border border-gray-300 rounded mt-1 w-full z-10 max-h-[150px] overflow-y-auto text-[grey]"
        >
          {techPreferenceData.map((option, idx) => (
            <label
              key={idx}
              className="flex items-center  p-2 hover:bg-gray-100 cursor-pointer"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "5px",
                // background: "#18141f05",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                value={option.value}
                checked={option.checked}
                onChange={handleTechPreferenceCheckboxChange}
                className="mr-2 "
                
                onClick={(e) => e.stopPropagation()}
              />
              {option.value}
              {option.value === "Other" && (
                        <input
                          type="text"
                          name="otherTech"
                          onChange={handleServiceDetails}
                          placeholder="Please specify"
                          className="ml-2 border rounded p-1"
                        />
                      )}
            </label>
          ))}
        </div>
        {/* {errors.techPreference && <span style={{ color: "red" }}>{errors.techPreference}</span>} */}
      </div>
    </div>
                    
{/* tech pref */}
{/* <label style={labelStyle} htmlFor="techPreference">
                Tech Preference
                <br />
                <div
                  style={{
                    width: isMobile ? "" : isTab ? "300px" : "400px",
                  }}
                  id="techPreference"
                  name="techPreference"
                >
                  {["WordPress", "Shopify", "React.js", "Angular", "Wix", "Squarespace", "PHP/HTML/CSS", "No Preference", "Other"].map((option, idx) => (
                    <label key={idx} className="flex items-center">
                      <input
                        type="checkbox"
                        value={option}
                        checked={formData.techPreference?.includes(option)}
                        onChange={(e) => handleCheckboxChange("techPreference", e)}
                        className="mr-2"
                      />
                      {option}
                      {option === "Other" && (
                        <input
                          type="text"
                          name="otherTech"
                          onChange={handleFormData}
                          placeholder="Please specify"
                          className="ml-2 border rounded p-1"
                        />
                      )}
                    </label>
                  ))}
                </div>
              </label> */}
                    {/* Revisions */}
                    <label
                      style={labelStyle}
                      className="formLabels"
                      htmlFor="designPreference"
                    >
                     Do you have any design preference? (Figma, Design Images)
                      <br />
                      {/* {errors.Revisions && (
                        <div style={{ color: "red", zIndex: 10000 }}>
                          {errors.Revisions}
                          <sup>*</sup>
                        </div>
                      )} */}
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        name="designPreference"
                        defaultValue=""
                        value={formData.serviceDetails.designPreference}
                        onChange={handleServiceDetails}
                        id="Revisions"
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] "
                      >
                         <option disabled value="">
                    Select
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                      </select>
                      
                    </label>
                    <label style={labelStyle} htmlFor="projectDetails" className="formLabels">
                Project Details
                <br />
              
                <textarea
                  id="projectDetails"
                  name="projectDetails"
                  value={formData.projectDetails.projectDetails}
                  onChange={handleProjectDetails}
                  rows="4"
                  className="bg-[#18141f05] w-[300px] text-[grey] p-2 rounded"
                  placeholder="Provide additional details about your project"
                ></textarea>
                {errors.projectDetails && <span style={{ color: "red" }}>{errors.projectDetails}</span>}
              </label>
                    {/* Branding assets */}
                    {/* <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      <label htmlFor="Assests">
                        Do you need additional branding assets along with the
                        logo?
                      </label>
                      <div
                        ref={(el) => (buttonRef.current["dropdown4"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown4")}
                      >
                        {formData.Assests.length > 0 ? (
                          formData.Assests.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>
                              Social media graphics, Business card design, Other
                            </p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown4"
                          ref={(el) => (dropdownRef.current["dropdown4"] = el)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 10001,
                          }}
                        >
                          {Assets.map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                                gap: "10px",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={formData.Assests.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("Assests", e)
                                }
                              />
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div>
                      </div>
                    </div> */}

                  

                    {/* Color */}
                    <div
                      style={{
                        position: "relative",
                        width: isMobile ? "90%" : isTab ? "300px" : "400px",
                      }}
                    >
                      {/* <label
                        htmlFor="Color"
                        classname="formLabels "
                        style={{ textAlign: "" }}
                      >
                        Any Color Scheme or Color You have in your Mind?
                      </label> */}
                      {/* <div
                        ref={(el) => (buttonRef.current["dropdown2"] = el)}
                        style={{
                          padding: "5px",
                          cursor: "pointer",
                          position: "relative",
                          background: "#18141f05",
                          textAlign: "left",
                        }}
                        onClick={() => toggleDropdown("dropdown2")}
                      >
                        {formData.Color.length > 0 ? (
                          formData.Color.join(", ")
                        ) : (
                          <div className="flex justify-between text-[#808080]">
                            <p>Red, Yellow, Blue, Black, Green Etc</p>
                            <p>
                              <IoIosArrowDown />
                            </p>
                          </div>
                        )}
                        <div
                          id="dropdown2"
                          ref={(e1) => (dropdownRef.current["dropdown2"] = e1)}
                          className="hidden"
                          style={{
                            position: "absolute",
                            background: "#fff",
                            border: "1px solid #ccc",
                            borderRadius: "5px",
                            width: "100%",
                            maxHeight: "150px",
                            overflowY: "auto",
                            zIndex: 1000,
                          }}
                        >
                          {Color.map((option) => (
                            <label
                              key={option}
                              style={{
                                display: "flex",
                                flexDirection: "row",
                                alignItems: "center",
                                padding: "5px",
                                background: "#18141f05",
                                gap: "10px",
                              }}
                            >
                              <input
                                type="checkbox"
                                value={option}
                                checked={formData.Color.includes(option)}
                                onChange={(e) =>
                                  handleCheckboxChange("Color", e)
                                }
                              />
                              {option.charAt(0).toUpperCase() + option.slice(1)}
                            </label>
                          ))}
                        </div>
                      </div> */}
                    </div>

                    {/* Delivery time */}
                    {/* <div>
                    <label
                      style={labelStyle}
                      classname="formLabels"
                      htmlFor="deliveryTime"
                    >
                      Delivery Time
                      <br />
                      {errors.deliveryTime && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                            zIndex: 1000,
                          }}
                        >
                          {errors.deliveryTime}
                          <sup>*</sup>
                        </div>
                      )}
                      <select
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        id="deliveryTime"
                        name="deliveryTime"
                        value={formData.deliveryTime}
                        onChange={handleFormData}
                        defaultValue=""
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                      >
                        <option disabled value="">
                          Same Day, 2 Days, 3 Days, 4 Days, 5 Days+
                        </option>
                        <option value="sameDay">Same day</option>
                        {/* <option value="1Day">1 day</option> */}
                        {/* <option value="2 Days">2 days</option>
                        <option value="3 Days">3 days</option>
                        <option value="4 Days">4 days</option>
                        <option value="5 days">5+ days</option> */}
                      {/* </select>
                    </label>
                    </div> */} 
                    <button
                      className="flex bg-[#1C6ED0] w-fit text-white px-7 py-1 rounded-sm"
                      onClick={handleNext}
                    >
                      Next
                    </button>
                    <br />
                  </>
                )
            }
            {
                currStep ===2 &&(
                    <>
                      <label style={labelStyle} htmlFor="contentReady">
                Do you have any content ready?
                <br />
                <select
                  id="contentReady"
                  name="contentReady"
                  value={formData.projectDetails.contentReady}
                  onChange={handleProjectDetails}
                  className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="yes">Yes</option>
                  <option value="no">No</option>
                </select>
              </label>

              <label style={labelStyle} htmlFor="domainHosting">
                Do you have domain and hosting?
                <br />
                <select
                  id="domainHosting"
                  name="domainHosting"
                  value={formData.serviceDetails.domainHosting}
                  onChange={handleServiceDetails}
                  className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="both">I already have both</option>
                  <option value="domain">I only have a domain</option>
                  <option value="hosting">I only have hosting</option>
                  <option value="help">I need help with both</option>
                </select>
              </label>
                {/* Budget */}
                <label className="block" htmlFor="budget">
                      <div
                        className="flex flex-col "
                        style={{
                          alignItems: "start",
                        }}
                      >
                        <span>Budget</span>
                        <br />
                        {/* {errors.priceRange && <span style={{ color: "red", display: "block", textAlign: "start" }}>{errors.priceRange}</span>} */}
                        <div
                          className="relative "
                          style={{
                            width: isMobile ? "90%" : isTab ? "300px" : "400px",
                          }}
                        >
                             {/* {errors.priceRange && <span style={{ color: "red", display: "block", textAlign: "start" }}>{errors.priceRange}</span>} */}
                          <ReactSlider
                            value={formData.projectDetails.priceRange}
                            onChange={handleSliderChange}
                            min={999}
                            max={10000}
                            step={500}
                            className="w-full h-2 bg-gray-300 rounded-full"
                            thumbClassName="w-4 h-4 bg-blue-500 rounded-full cursor-pointer -mt-[3px] "
                            trackClassName="bg-blue-500 rounded-full "
                            renderThumb={(props) => <div {...props} />} // Remove default value display
                          
                          />
                          {/* {errors.priceRange && <span style={{ color: "red", display: "block", textAlign: "start" }}>{errors.priceRange}</span>} */}
                          

                          <div className="flex justify-between text-sm text-gray-600 mt-2">
                            <span>₹{formData.projectDetails.priceRange[0]}</span>
                            <span>₹{formData.projectDetails.priceRange[1]}</span>
                          </div>
                         
                         
                        </div>
                       
                      </div>
                     
                    </label>
                

              {/* <label style={labelStyle} htmlFor="decisionParameters">
                Key Decision Parameters
                <br />
                <div
                  style={{
                    width: isMobile ? "" : isTab ? "300px" : "400px",
                  }}
                  id="decisionParameters"
                  name="decisionParameters"
                >
                  {["Price", "Experience", "Quality work", "Timeline", "Cost structure"].map((option, idx) => (
                    <label key={idx} className="flex items-center">
                      <input
                        type="checkbox"
                        value={option}
                        checked={formData.decisionParameters.includes(option)}
                        onChange={(e) => handleCheckboxChange("decisionParameters", e)}
                        className="mr-2"
                      />
                      {option}
                    </label>
                  ))}
                </div>
              </label> */}
              {/* updated key decision parameers */}
              <div>
      <label style={labelStyle} htmlFor="decisionParameters"  className="formLabels">
        Key Decision Parameters
      </label>
      <div
        id="dropdown5"
        style={{
            width: isMobile ? "" : isTab ? "300px" : "400px",
          }}
        className="relative"
      >
        <div
          ref={(el) => (buttonRef.current["dropdown5"] = el)}
          onClick={() => toggleDropdown("dropdown5")}
          className="bg-[#18141f05] p-2 cursor-pointer rounded flex justify-between items-center"
        >
          <span className="text-[grey]">
            {formData.serviceDetails.decisionParameters.length > 0
              ? formData.serviceDetails.decisionParameters.join(", ")
              : "Select Key Decision Parameters"}
          </span>
          <IoIosArrowDown />
        </div>
        <div
          ref={(el) => (dropdownRef.current["dropdown5"] = el)}
          className="hidden absolute bg-white border border-gray-300 rounded mt-1 w-full z-10 max-h-[150px] overflow-y-auto text-[grey]"
        >
          {decisionParametersData.map((option, idx) => (
            <label
              key={idx}
              className="flex items-center  p-2 hover:bg-gray-100 cursor-pointer"
              style={{
                display: "flex",
                flexDirection: "row",
                alignItems: "center",
                padding: "5px",
                // background: "#18141f05",
                gap: "10px",
              }}
            >
              <input
                type="checkbox"
                value={option.value}
                checked={option.checked}
                // onChange={handleCheckboxChange}
                onChange={handleDecisionCheckboxChange}
                className="mr-2 "
                
                onClick={(e) => e.stopPropagation()}
              />
              {option.value}
              {option.value === "Other" && (
                        <input
                          type="text"
                          name="otherTech"
                          onChange={handleServiceDetails}
                          placeholder="Please specify"
                          className="ml-2 border rounded p-1"
                        />
                      )}
                     
            </label>
            
          ))}
        </div>
        {errors.decisionParameters && <span style={{ color: "red", display: "block", textAlign: "start" }}>{errors.decisionParameters}</span>}
      </div>
    </div>

              <label style={labelStyle} htmlFor="projectTimeline">
                Timeline of the project
                <br />
                <select
                  id="projectTimeline"
                  name="projectTimeline"
                  value={formData.projectDetails.projectTimeline}
                  onChange={handleProjectDetails}
                  className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option value="week">Week</option>
                  <option value="twoWeeks">Two weeks</option>
                  <option value="month">Month</option>
                  <option value="twoMonths">Two months</option>
                </select>
                {errors.projectTimeline && <span style={{ color: "red", display: "block", textAlign: "start" }}>{errors.projectTimeline}</span>}
              </label>

              <label style={labelStyle} htmlFor="projectStart">
                How soon do you want to start this project?
                <br />
                {enterDate?(<input type="date" id="projectStart" name="projectStart" onChange={handleDataChange} value={formData.serviceDetails.projectStart}/>):<select
                  id="projectStart"
                  name="projectStart"
                  value={formData.serviceDetails.projectStart}
                  onChange={handleServiceDetails}
                  className="bg-[#18141f05] w-[300px] text-[grey] h-[30px]"
                >
                  <option disabled value="">
                    Select
                  </option>
                  <option onClick={()=>setEnterDate(true)}>Enter Date</option>
                  <option value="notDecided">Not decided as of now</option>
                </select>}
                
</label>

              <button
                className="flex bg-[#1C6ED0] w-fit text-white px-7 my-4 py-1 rounded-sm"
                onClick={handleNext}
              >
                Next
              </button>
                    </>
                )
            }
                {currStep === 3 && ( 
                  <>
                    <label style={labelStyle} >
                      First Name
                      <br />
                      {errors.fullName && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.fullName}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="text"
                        name="fullName"
                        value={formData.personalDetails.fullName}
                        onChange={handlePersonalDetails}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Enter your first name here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Business Name
                      <br />
                      <input
                        type="text"
                        name="business"
                        value={formData.personalDetails.business}
                        onChange={handlePersonalDetails}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Company Name"
                      />
                    </label>
                    <label style={labelStyle}>
                      Email
                      <br />
                      {errors.email && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.email}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="email"
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        name="email"
                        value={formData.personalDetails.email}
                        onChange={handlePersonalDetails}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Enter your email here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Phone Number
                      <br />
                      {errors.phone && (
                        <div
                          style={{
                            color: "red",
                            display: "flex",
                            alignItems: "center",
                          }}
                        >
                          {errors.phone}
                          <sup>*</sup>
                        </div>
                      )}
                      <input
                        type="text"
                        value={formData.personalDetails.phone}
                        name="phone"
                        onChange={handlePersonalDetails}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="Enter your phone number here"
                      />
                    </label>
                    <label style={labelStyle}>
                      Website
                      <br />
                      <input
                        type="text"
                        value={formData.personalDetails.website}
                        name="website"
                        onChange={handlePersonalDetails}
                        style={{
                          width: isMobile ? "" : isTab ? "300px" : "400px",
                        }}
                        className="bg-[#18141f05] w-[300px] text-[grey] h-[30px] pl-2 placeholder-gray-500 rounded"
                        placeholder="www.bizzowl.com"
                      />
                    </label>
                    <button
                      className="flex bg-[#1C6ED0] w-fit text-white px-7 py-1 rounded-sm"
                      onClick={handleSubmit}
                    >
                      View plans
                    </button>
                    <br />
                  </>
                )}
              </form>
            </div>
          </div>

          <br />
          <br />
          {currStep !== 1 &&currStep !== 2 && (
            <div className="mb-10 w-[60%] mx-auto">
              <p>
                By clicking on "View Plans", you agree to our
                <span className="text-[#1C6ED0]"> Privacy Policy</span>  and
                <span className="text-[#1C6ED0]">Terms of Use</span>
              </p>
            </div>
          )}
        </div>
      </div>
)
};

export default WebsiteDevelopmentForm;
