import React, { useEffect, useState } from "react";
import Herosection from "./components/Herosection";
import Heading from "./components/Heading";
import Card from "./components/Card";
import leftAr from "./components/assets/leftAr.png";
import rightAr from "./components/assets/rightAr.png";
import blogImg from "./components/assets/blogImg.png";
import marketImg from "./components/assets/marketImg.png";
import VideoCard from "./components/VideoCard";
import { Helmet } from "react-helmet";
import NavBar from "../Header/NavBar";
import Footer from "../Footer/Footer";
import ApplyBodyStylesOnLoad from "../ApplyBodyStylesOnLoad ";
import { useMediaQuery } from "@mui/material";
import { adminDB } from "../../config/firebase";
import { collection, doc, getDoc, getDocs } from "firebase/firestore";
import SpecificBlog7_img1 from "../../Views/Auth/assets/blogImages/SpecificBlog7/SpecificBlog7_img1.jpg"
import SpecificBlog8_img1 from "../../Views/Auth/assets/blogImages/SpecificBlog8/SpecificBlog8_img1.jpg"

const dataForMRCard = [
  {
    id: "pet-care-sector",
    category: "Public Relations",
    heading: "A complete Overview on Petcare industry",
    description:
      "In today's digital age, getting your message out to the right customer segment or audience is crucial for businesses of all sizes. .....",
    btn: "View",
    date: "14-Sep-2024",
  },
];

const blogPosts = [
  {
    id: 1,
    icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1697129744/Bizowl/blog1_mjudxr.jpg",
    jobTitle: "Business Service",
    tag: "bs",
    urlTitle:
      "the-importance-of-an-end-to-end-business-service-aggregating-platform",
    title:
      "The Importance of an End-to-End Business Service Aggregating Platform",
    description:
      "In today's fast-paced business world, small business owners and startup entrepreneurs ...",
    date: "12-Oct-2023",
  },
  {
    id: 2,
    icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1721878655/bizowl-blog-2_di2op8.png",
    jobTitle: "Public Relations",
    tag: "pr",
    urlTitle: "21-benefits-of-press-release-distribution",
    title:
      "21 Benefits of Press Release Distribution: Elevate Your Brand's Visibility and Credibility",
    description:
      "Discover 21 powerful benefits of press release distribution that can elevate your brand's .....",
    date: "24-Jul-2024",
  },
  {
    id: 3,
    icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1723669728/bizowl-blog-3_vwmmib.png",
    jobTitle: "Public Relations",
    tag: "pr",
    urlTitle: "best-press-release-distribution-services",
    title:
      "Finding the Best Press Release Distribution Services: A Comprehensive Guide",
    description:
      "Discover the best press release distribution services to boost your media coverage and....",
    date: "15-Aug-2024",
  },
  {
    id: 4,
    icon: "https://res.cloudinary.com/dwjzihwuq/image/upload/v1724100311/photo1_yntb2p.png",
    jobTitle: "Public Relations",
    tag: "pr",
    urlTitle:
      "top-benefits-of-using-an-aggregator-for-online-press-release-distribution",
    title:
      "Top Benefits of Using an Aggregator for Online Press Release Distribution",
    description:
      "Discover how using an aggregator for online press release distribution can streamline ...",
    date: "20-Aug-2024",
  }, 
  {
    id: 5,
    icon: "https://res.cloudinary.com/dcduojrhf/image/upload/v1731077653/Importance_of_business_COnsulatncy_rd58t2.png",
    jobTitle: "Business Consulting",
    tag: "bc",
    urlTitle:
      "pre-seed-to-seed-why-earlystage-startups-need-business-consulting-a-complete-guide",
    title:
      "Pre-seed to Seed: Why EarlyStage Startups Need Business Consulting (A Founder's Complete Guide",
    description:
      "Remember learning to drive? You could've figured it out alone, but having an experienced...",
    date: "08-Oct-2024",
  },
  // {
  //   id: 6,
  //   icon: "https://res.cloudinary.com/dr1pmtefx/image/upload/v1697129744/Bizowl/blog1_mjudxr.jpg",
  //   jobTitle: "Business Consulting",
  //   tag: "bs",
  //   urlTitle:
  //     "the-importance-of-an-end-to-end-business-service-aggregating-platform",
  //   title:
  //     "The Importance of an End-to-End Business Service Aggregating Platform",
  //   description:
  //     "In today's fast-paced business world, small business owners and startup entrepreneurs face...",
  //   date: "08-Oct-2024",
  // },
  {
    id: 7,
    icon: SpecificBlog7_img1,
    jobTitle: "Business Consulting",
    tag: "bc",
    urlTitle:
      "why-small-and-medium-enterprises-need-professional-consulting-services",
    title:
      "Why Small and Medium Enterprises Need Professional Consulting Services",
    description:
      "In an era where business complexity intersects with unprecedented opportunities...",
    date: "08-Oct-2024",
  },
  {
    id: 8,
    icon: SpecificBlog8_img1,
    jobTitle: "Business Consulting",
    tag: "bs",
    urlTitle:
      "10-marketing-strategies-to-revive-a-stagnant-user-base",
    title:
      "10 Marketing Strategies to Revive a Stagnant User Base",
    description:
      "Imagine pouring your heart and soul into a business, only to watch your user base plateau....",
    date: "08-Oct-2024",
  },
];

const Resources = () => {
  const [posts, setPosts] = useState([]);
  const [loading, setLoading] = useState(true);

  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     try {
  //       const querySnapshot = await getDocs(collection(adminDB, "blogPosts"));
  //       const postsData = querySnapshot.docs.map((doc) => {
  //         const data = doc.data(); // Get the data of the document
  //         const id = doc.id; // Get the document ID

  //         console.log("Document ID:", id); // Log the document ID
  //         console.log("Document Data:", data); // Log the document data

  //         return { id, ...data }; // Return an object with the id and the data
  //       });
  //       setPosts(postsData);

  //       // Set the fetched data into state
  //     } catch (error) {
  //       console.error("Error fetching posts: ", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPosts();
  // }, []);
  const isMobile = useMediaQuery("(max-width:650px)");
  const isSmallMobile = useMediaQuery("(max-width:400px)");
  const VideoCompData = [
    {
      h1: "After PMF : People Customer Sucess",
      h3: " Marketing",
      videoId: "sO4te2QNsHY",
    },
    {
      h1: "After PMF : People Customer Sucess",
      h3: "Marketing",
      videoId: "gEiK-bDOADg",
    },
    {
      h1: "After PMF : People Customer Sucess",
      h3: "Marketing",
      videoId: "dpyGKtkYubY",
    },
  ];
  return (
    <div className="overflow-x-hidden">
      <ApplyBodyStylesOnLoad />
      <Helmet>
        <meta charset="UTF-8" />
        <meta name="viewport" content="width=device-width, initial-scale=1.0" />
        <title>
          Business Resources Hub | Guides, Templates, Blogs & Market Research
        </title>
        <meta
          name="description"
          content="Explore Bizowl's comprehensive business resources including guides, templates, blogs, eBooks, market research, videos, and success stories to help you grow your business."
        />
        <meta
          name="keywords"
          content="business resources, guides, templates, blogs, market research, eBooks, success stories, case studies, business knowledge base, learning resources, business guides"
        />
        <meta name="robots" content="index, follow" />
        <meta name="author" content="Bizowl" />
        <meta http-equiv="Content-Language" content="en" />
        <link rel="canonical" href="https://bizzowl.com/resources " />

        <meta
          property="og:title"
          content="Business Resources Hub | Guides, Templates, Blogs & Market Research"
        />

        <meta
          property="og:description"
          content="Unlock Bizowl's extensive resources to solve your business challenges. Access blogs, guides, templates, eBooks, market research, and more for your business growth."
        />

        <meta
          property="og:image"
          content="https://bizzowl.com/Images/logos/resources.png"
        />
        <meta
          property="og:url"
          content="Bizowl - Business Services: From Idea to Growth"
        />

        <meta name="twitter:card" content="summary_large_image" />

        <meta
          name="twitter:title"
          content="Business Resources Hub | Guides, Templates, Blogs & Market Research"
        />

        <meta
          name="twitter:description"
          content="Access a wide range of business resources like blogs, guides, templates, eBooks, and success stories to help you navigate business challenges."
        />

        <meta
          name="twitter:image"
          content="Bizowl - Business Services: From Idea to Growth"
        />
        <script type="application/ld+json">
          {`
  "@context": "https://schema.org",
  "@type": "WebPage",
  "name": "Bizowl Resources",
  "url": "https://bizzowl.com/resources",
  "description": "Access a comprehensive knowledge base offering blogs, guides, templates, eBooks, market research, videos, success stories, and case studies to help solve your business challenges.",
  "about": {
    "@type": "Thing",
    "name": "Business Knowledge Base",
    "description": "A variety of resources to help entrepreneurs and businesses overcome challenges through expert content."
  },
  "mainEntity": [
    {
      "@type": "CreativeWork",
      "name": "Blogs",
      "description": "Explore in-depth articles on business strategies, marketing, and more."
    },
    {
      "@type": "CreativeWork",
      "name": "Guides",
      "description": "Step-by-step guides on business development, marketing, and technology."
    },
    {
      "@type": "CreativeWork",
      "name": "Templates",
      "description": "Ready-to-use business templates for planning, budgeting, and operations."
    },
    {
      "@type": "CreativeWork",
      "name": "eBooks",
      "description": "Comprehensive eBooks covering various aspects of starting and growing a business."
    },
    {
      "@type": "CreativeWork",
      "name": "Market Research",
      "description": "Data-driven insights to inform your business decisions and strategies."
    },
    {
      "@type": "CreativeWork",
      "name": "Videos",
      "description": "Watch success stories, case studies, and expert advice in video format."
    },
    {
      "@type": "CreativeWork",
      "name": "Case Studies",
      "description": "Real-world examples of successful businesses and strategies."
    }
  ],
  "author": {
    "@type": "Organization",
    "name": "Bizowl",
    "url": "https://bizzowl.com"
  },
  "potentialAction": {
    "@type": "SearchAction",
    "target": "https://bizzowl.com/resources?q={search_term_string}",
    "query-input": "required name=search_term_string"
  } `}
        </script>
      </Helmet>
      <NavBar />
      {/* <Header /> */}
      <div
        className="bg-gradient-to-r from-blue-100 text-left"
        style={{
          paddingTop: isSmallMobile ? "60px" : isMobile ? "80px" : "0px",
        }}
      >
        {/* <div>Resources Section</div> */}
        <Herosection />
        <Heading name={"Blogs"} category="blogs" />
        <div className=" w-full flex flex-wrap justify-start px-[7%]">
          <Card
            img={blogPosts[0].icon}
            heading={blogPosts[0].description}
            time={blogPosts[0].date}
            category={blogPosts[0].jobTitle}
            btn={"Read More"}
            type="blogs"
            tag={blogPosts[0].tag}
            urlTitle={blogPosts[0].urlTitle}
          />
          <Card
            img={blogPosts[1].icon}
            heading={blogPosts[1].description}
            time={blogPosts[1].date}
            category={blogPosts[1].jobTitle}
            btn={"Read More"}
            type="blogs"
            tag={blogPosts[1].tag}
            urlTitle={blogPosts[1].urlTitle}
          />
          <Card
            img={blogPosts[2].icon}
            heading={blogPosts[2].description}
            time={blogPosts[2].date}
            category={blogPosts[2].jobTitle}
            btn={"Read More"}
            type="blogs"
            tag={blogPosts[2].tag}
            urlTitle={blogPosts[2].urlTitle}
          />

          <Card
            img={blogPosts[3].icon}
            heading={blogPosts[3].description}
            time={blogPosts[3].date}
            category={blogPosts[3].jobTitle}
            btn={"Read More"}
            type="blogs"
            tag={blogPosts[3].tag}
            urlTitle={blogPosts[3].urlTitle}
          />
          <Card
            img={blogPosts[4].icon}
            heading={blogPosts[4].description}
            time={blogPosts[4].date}
            category={blogPosts[4].jobTitle}
            btn={"Read More"}
            type="blogs"
            tag={blogPosts[4].tag}
            urlTitle={blogPosts[4].urlTitle}
          />


          <Card
            img={blogPosts[5].icon}
            heading={blogPosts[5].description}
            time={blogPosts[5].date}
            category={blogPosts[5].jobTitle}
            btn={"Read More"}
            type="blogs"
            tag={blogPosts[5].tag}
            urlTitle={blogPosts[5].urlTitle}
          />

<Card
            img={blogPosts[6].icon}
            heading={blogPosts[6].description}
            time={blogPosts[6].date}
            category={blogPosts[6].jobTitle}
            btn={"Read More"}
            type="blogs"
            tag={blogPosts[6].tag}
            urlTitle={blogPosts[6].urlTitle}
          />
          {/* {posts.map((data) => {
            return (
              <Card
                img={data?.blogCard}
                heading={data?.blogTitle}
                time={data?.blogDate}
                category={data?.metaTags}
                btn={"Read More"}
                type="blogs"
                tag={data?.metaTags}
                urlTitle={data?.metaTitle}
              />
            );
          })} */}
        </div>
        <div className="hidden xl:block">
          <div className="my-8  flex justify-evenly">
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={rightAr} alt="img" />
            </button>
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={leftAr} alt="img" />
            </button>
          </div>
        </div>
        <Heading name={"Market Research"} category="market-research" />
        <div className=" w-full flex flex-wrap justify-start px-[7%]">
          <Card
            img={marketImg}
            id={dataForMRCard[0].id}
            heading={dataForMRCard[0].heading}
            description={dataForMRCard[0].description}
            category={dataForMRCard[0].category}
            btn={"View"}
            type="pdf"
            time={dataForMRCard[0].date}
          />
        </div>
        <Heading name={"Videos"} category="video-library" />
        <div className="w-full flex flex-wrap justify-start  px-[7%] ">
          <VideoCard
            h1={VideoCompData[0].h1}
            h3={VideoCompData[0].h3}
            videoId={VideoCompData[0].videoId}
          />
          <VideoCard
            h1={VideoCompData[1].h1}
            h3={VideoCompData[1].h3}
            videoId={VideoCompData[1].videoId}
          />
          <VideoCard
            h1={VideoCompData[2].h1}
            h3={VideoCompData[2].h3}
            videoId={VideoCompData[2].videoId}
          />
          <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3} />
          <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3} />
          <VideoCard h1={VideoCompData[0].h1} h3={VideoCompData[0].h3} />
        </div>
        <div className="hidden xl:block">
          <div className="my-8 pb-[300px] flex justify-evenly">
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={rightAr} alt="img" />
            </button>
            <button className="w-6 h-6 md:w-8 md:h-8 inline mx-2 p-1 bg-[#407BFF] rounded-3xl">
              <img src={leftAr} alt="img" />
            </button>
          </div>
        </div>
        <div></div>
        <Footer />
      </div>
    </div>
  );
};

export default Resources;
