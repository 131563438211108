import React, { useEffect, useState } from "react";
import { BsThreeDotsVertical } from "react-icons/bs";
import {
  Box,
  Button,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  IconButton,
  Img,
  Input,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  Text,
} from "@chakra-ui/react";

import FileImage from "../../assets/File-Preview 1.png";
// import { LuCalendarDays, LuEye } from "react-icons/lu";
import { ModalBody } from "react-bootstrap";
import { GoPaperclip } from "react-icons/go";

import { MdDelete } from "react-icons/md";
const PortfolioCard = () => {
  return (
    <Box
      mt="1rem"
      borderRadius='1rem'
      marginBottom={"1.2rem"}
      objectFit="cover"
      overflow="hidden"
      height={{ sm: "10rem", md: "auto" }}
      fontSize={{ sm: ".5rem", md: "1rem" }}
      boxShadow={"0rem 0.25rem 0.25rem 0rem rgba(0, 0, 0, 0.25)"}
      backgroundColor="#FFFFFF"
      padding={".8rem"}
    >
      <Text fontWeight="bold">{"Project Name"}</Text>
      <Flex
        my={".8rem"}
        height={"9rem"}
        width={"18rem"}
        borderRadius={".9rem"}
        alignItems={'center'}
        backgroundColor="#D7E9FD"
        justifyContent={'center'}
      >
        <Img src={FileImage} height={"8rem"} />
      </Flex>
      <Flex flexDirection="column" m={{ sm: ".5rem", md: "1rem" }}>
        <Flex justifyContent="space-between">
          <Text>{"Client Name"}</Text>
          <Flex alignItems="center" cursor={"pointer"}>
            {/* <Icon as={LuEye} me=".3rem" /> */}
            <Text>View Details</Text>
          </Flex>
        </Flex>
        <Text color="#007CFF">{"Web Development"}</Text>
        <Flex alignItems="center" gap=".3rem">
          {/* <Icon as={LuCalendarDays} /> */}
          <Text>{"07-07-2025"}</Text>
        </Flex>
      </Flex>
    </Box>
  );
};
export default PortfolioCard;