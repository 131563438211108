import React, { useEffect, useState } from "react";
import { Col, Row } from "react-bootstrap";
import "../../../Styles/AuthStyles/homepageStyle.scss";
import "../../../Styles/Fixed/staticStyle.scss";
import "../../../Styles/Fixed/fixedDesign.scss";
// import {
//   BsArrowBarLeft,
//   BsArrowBarRight,
//   BsChatRight,
//   BsChevronBarRight,
//   BsChevronRight,
//   BsFillStarFill,
//   BsSearch,
// } from "react-icons/bs";
// import { ImQuotesLeft } from "react-icons/im";
// import { RxCaretLeft, RxCaretRight, RxPinRight } from "react-icons/rx";
import {
  // AskBigCard,
  // BlogsBigCard,
  // BlogsBigCard2,
  // BlogsSmallCard,
  ContactUsDesign,
  // HowCard,
  // HowCardCircle,
  // HowCardRound,
  // IndustriesBigCard,
  // IndustriesSmallCard,
  // OurServicesCard,
  SubHeader,
  // TestimonialDesign,
  // WhyBestCard,
  // WhyUsCard,
} from "../../../Components/Design/fixedDesigns";
// import { useState } from "react";
// import Faq from "react-faq-component";
// import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import {
  ellipsisText,
  // TestimonialsSlide,
} from "../../../Components/Extra/extraFunction";
// import { NavLink } from "react-router-dom";
import NavBar from "../../../Components/Header/NavBar";
import Footer from "../../../Components/Footer/Footer";
import { useMediaQuery } from "@mui/material";
import { collection, getDocs } from "firebase/firestore";
import { adminDB } from "../../../config/firebase";
import { useParams } from "react-router-dom";

const SpecificBlogs = () => {
  const { urlTitle } = useParams();
  const [posts, setPosts] = useState([]);
  // const [displayPosts, setDisplayPosts] = useState([]);
  const [loading, setLoading] = useState(true);
  // useEffect(() => {
  //   const fetchPosts = async () => {
  //     try {
  //       const querySnapshot = await getDocs(collection(adminDB, "blogPosts"));
  //       const postsData = querySnapshot.docs.map((doc) => {
  //         const data = doc.data(); // Get the data of the document
  //         const id = doc.id; // Get the document ID

  //         return { id, ...data }; // Return an object with the id and the data
  //       });
  //       setPosts(postsData);

  //       // Set the fetched data into state
  //     } catch (error) {
  //       console.error("Error fetching posts: ", error);
  //     } finally {
  //       setLoading(false);
  //     }
  //   };

  //   fetchPosts();
  // }, []);
  const displayPost = posts?.filter((data) => {
    return data.urlTitle === urlTitle;
  });
  console.log(displayPost);
  const { content, metaTags, blogDate, blogCard } = displayPost[0] || {};

  const isMobile = useMediaQuery("(max-width: 768px)");
  return (
    <>
      <NavBar />
      <div className="homepageContainer">
        {SubHeader("Blogs", "blogs", "home", "home")}

        <div className="bg-white rounded-lg shadow-md mx-auto max-w-7xl my-4">
          <div className="blogsBigCardLogo padding10 px-4">
            <img
              className="blogsBigCardImg fullHeight"
              style={{
                maxHeight: "500px",
                width: "100%",
                objectFit: "contain",
              }}
              alt="The Importance of an End-to-End Business Service Aggregating Platform"
              src={blogCard}
            />
          </div>
          <div className="flex px-4">
            <div className="blogsDate">{metaTags}</div> &nbsp;&nbsp; {blogDate}
          </div>
          <div className="px-4">
            <div dangerouslySetInnerHTML={{ __html: content }} />
          </div>
        </div>

        <div className="contactUs">{ContactUsDesign()}</div>
      </div>
      <Footer />
    </>
  );
};
export default SpecificBlogs;
