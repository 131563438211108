export const DecisionParameters=[
    {value:"Price",checked:false},
    {value:"Experience",checked:false},
    {value:"Quality work",checked:false},
    {value:"Timeline",checked:false},
    {value:"Cost structure",checked:false}
];

export const RequiredFeatures=[
    {value:"Live Chat",checked:false},
    {value:"Contact/Feedback Forms",checked:false},
    {value:"SEO Optimization",checked:false},
    {value:"Payment Gateway Integration",checked:false},
    {value:"Social Media Integration",checked:false},
    {value:"Newsletter Subscription",checked:false},
    {value:"User Accounts/Registration",checked:false},
    {value:"Multilingual Support",checked:false},
    {value:"Search Functionality",checked:false},
    {value:"Analytics and Reporting Tools",checked:false},
    {value:"Custom Animations",checked:false},
    {value:"Video or Audio Integration",checked:false},
    {value:"Booking/Calendar System",checked:false},
    {value:"Product Catalog",checked:false},
    {value:"Not Sure",checked:false},
    {value:"Other",checked:false},
];

export const TechPreference=[
    {value:"WordPress",checked:false},
    {value:"Shopify",checked:false},
    {value:"React.js",checked:false},
    {value:"Angular",checked:false},
    {value:"Wix",checked:false},
    {value:"Squarespace",checked:false},
    {value:"PHP/HTML/CSS",checked:false},
    {value:"No Preference",checked:false},
    {value:"Other",checked:false},
]
