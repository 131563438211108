import React from "react";
import Star from "../../assets/solar_star-bold.png";


const ReviewCard = () => {
  return (
    <div
      style={{
        // backgroundImage: `linear-gradient(to bottom ,#d9eafd, white)`,
        backgroundColor:'#d9eafd',
        color: "#455A64",
      }}
      className="flex flex-col gap-2 text-start w-[313px] p-3 my-2 rounded-[1rem] overflow-hidden"
    >
      <p className="w-full">Shopify Development</p>
      <p className="font-extrabold text-xl">
        Everything went really good with Bizowl team, The quality of work was
        great.
      </p>
      <div className="flex w-full items-center">
        {Array.from({ length: 5 }, (_, index) => (
          <img key={index} src={Star} width={"20px"} />
        ))}
        <b className="ml-2"> 5</b>
      </div>
      <div className="w-full border-t-2 border-white">
        <p>First Name</p>
      </div>
    </div>
  );
};

export default ReviewCard;
