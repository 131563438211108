import MessageImg from "./assets/Vector (2).png";

const ChatPopup = ({setIsVisible,row}) => {
  return (
    <div className="flex w-full border-2 text-sm md:text-lg font-Cabin bg-[#f7fcfb] overflow-hidden rounded-[1rem] md:rounded-[1.7rem] z-10 flex-col">
      <div className="bg-[#1C6ED0] py-2 px-3 md:px-4 w-full flex justify-between items-center">
        <div className="flex gap-2">
          <img src={MessageImg} alt="MessageImg" width={'25rem'}/>
          <p className="font-bold  text-white">Chat Window</p>
        </div>
        <button
          className="rounded-[.6rem] bg-transparent text-white p-0 "
          onClick={() => setIsVisible()}
        >
          X
        </button>
      </div>
      <div className="w-full p-2 flex md:pb-3 md:p-4 flex-col ">

        <textarea
          name="chat"
          id="chat"
          rows={row}
          className="w-full resize-none border-2 rounded-[.5rem] p-2"
          placeholder="Type Your Message"
        ></textarea>

        <button className="btn btn-primary ml-auto rounded-[.4rem] md:px-3 mt-3 btn-md ">
          Send
        </button>
      </div>
    </div>
  );
};

export default ChatPopup;
